import {Component, Input, OnInit} from '@angular/core';
import {FormatFeedbackGroup} from "../../utils/format-feedback-group";
import {PromotionApiResourceV2} from "../../../generated/cronos/resources";
import {Router} from "@angular/router";
import {FeedbackSelectorService, SeminarIdentifier} from "../../utils/feedback-selector.service";

@Component({
  selector: 'app-feedback-selector',
  templateUrl: './feedback-selector.component.html',
  styleUrls: ['./feedback-selector.component.scss', '../../feedback-overview.component.scss']
})
export class FeedbackSelectorComponent implements OnInit {


  feedbackGroups: FormatFeedbackGroup[] = [
    new FormatFeedbackGroup(SeminarIdentifier.All, this.selectorService.formatToIds[SeminarIdentifier.All].ids),
    new FormatFeedbackGroup(SeminarIdentifier.Steuer, this.selectorService.formatToIds[SeminarIdentifier.Steuer].ids),
    new FormatFeedbackGroup(SeminarIdentifier.Thesis, this.selectorService.formatToIds[SeminarIdentifier.Thesis].ids),
    new FormatFeedbackGroup(SeminarIdentifier.Excel, this.selectorService.formatToIds[SeminarIdentifier.Excel].ids),
    new FormatFeedbackGroup(SeminarIdentifier.Finance, this.selectorService.formatToIds[SeminarIdentifier.Finance].ids),
    new FormatFeedbackGroup(SeminarIdentifier.Fin4Women, this.selectorService.formatToIds[SeminarIdentifier.Fin4Women].ids)
  ];

  @Input()
  selectedSeminar: SeminarIdentifier;

  initialized:boolean = false

  constructor(public promotionApiResourceV2: PromotionApiResourceV2,
              public router: Router,
              public selectorService: FeedbackSelectorService) { }

  ngOnInit(): void {
    this.loadAverageRatings()

  }

  getGroup(identifier: SeminarIdentifier):FormatFeedbackGroup{
    return this.feedbackGroups.find(e => e.identifier == identifier)
  }

 loadAverageRatings() {
  if(this.selectorService.seminarFeedbackData[SeminarIdentifier.All].feedbackData.averageRating != null){
    this.feedbackGroups.forEach(group=>{
      group.data = this.selectorService.seminarFeedbackData[group.identifier].feedbackData
    })
    this.initialized = true
    return
  }

  //Loads average rating information and saves it in service, this way we only have one initial noticeable load time and instant information the other times
   let loaded = 0;
   this.feedbackGroups.forEach(group => {
       this.promotionApiResourceV2.getRatingSummary({formatIds: group.formatIds}).then(res => {
         group.data = res
         this.selectorService.seminarFeedbackData[group.identifier].feedbackData = res
         loaded++;
         if(loaded == this.feedbackGroups.length -1){
           this.initialized = true
         }
       });
     }
   )
  }

  protected readonly SeminarIdentifiers = SeminarIdentifier;
    protected readonly Array = Array;
}
