import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../../api-resource";
import {CareerArticleData, CareerArticleSplitData, PagedData} from "../data"

/**
 * This resource class was generated from Controller net.innospire.hidcms.controllers.public.PublicCareerArticleController
 */

@Injectable()
@ResourceParams({})
export class PublicCareerArticleResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/careerArticle/all',
        endpoint: 'cms'
    })
    private _getAllArticles: IResourceMethodObservableStrict<void, {page?: number, pageSize?: number}, {}, PagedData<CareerArticleData>>;
    getAllArticles(requestParams?: {page?: number, pageSize?: number}): Promise<PagedData<CareerArticleData>> {
        return this._getAllArticles(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/careerArticle/{routingUrl}/cardBanner',
        endpoint: 'cms'
    })
    private _getArticleCardBanner: IResourceMethodObservableStrict<void, {}, {routingUrl: string}, Blob>;
    getArticleCardBanner(routingUrl: string): Promise<Blob> {
        return this._getArticleCardBanner(null, null, {routingUrl: routingUrl}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/careerArticle/{routingUrl}',
        endpoint: 'cms'
    })
    private _getArticleSplit: IResourceMethodObservableStrict<void, {splitId?: number}, {routingUrl: string}, CareerArticleSplitData>;
    getArticleSplit(routingUrl: string, requestParams?: {splitId?: number}): Promise<CareerArticleSplitData> {
        return this._getArticleSplit(null, requestParams, {routingUrl: routingUrl}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/careerArticle/{routingUrl}/banner',
        endpoint: 'cms'
    })
    private _getArticleSplitBanner: IResourceMethodObservableStrict<void, {splitId?: number}, {routingUrl: string}, Blob>;
    getArticleSplitBanner(routingUrl: string, requestParams?: {splitId?: number}): Promise<Blob> {
        return this._getArticleSplitBanner(null, requestParams, {routingUrl: routingUrl}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/careerArticle/banner',
        endpoint: 'cms'
    })
    private _getPreviewBanner: IResourceMethodObservableStrict<void, {previewId: string}, {}, Blob>;
    getPreviewBanner(requestParams: {previewId: string}): Promise<Blob> {
        return this._getPreviewBanner(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/careerArticles/preview/{id}',
        endpoint: 'cms'
    })
    private _getSplitPreview: IResourceMethodObservableStrict<void, {}, {id: string}, CareerArticleSplitData>;
    getSplitPreview(id: string): Promise<CareerArticleSplitData> {
        return this._getSplitPreview(null, null, {id: id}).toPromise()
    }

}