<div class="content-wrapper">

  <h1 class="mt-3">Hochschulinitiative Deutschland Erfahrungen -<br> So bewerten Studierende {{seminarIdentity | seminarHeader}}</h1>
  <label class="text-regular text-grey sub-heading">
    {{seminarIdentity | feedbackPageHeading}}
  </label>

  <app-feedback-selector [selectedSeminar]="seminarIdentity"></app-feedback-selector>

  <mat-checkbox class="filter-comment-checkbox" [checked]="filterForComments" (change)="filterFeedbacksForComments($event.checked)">Nur Feedbacks
    mit Kommentaren anzeigen
  </mat-checkbox>

  <h2>Alle Bewertungen {{seminarIdentity | seminarFeedbackListHeader}} der Hochschulinitiative Deutschland</h2>
  <div class="grid" *ngIf="feedbackLoading">
    <ng-container *ngFor="let i of Array(pageSize).fill(1)" fxLayout="column" fxLayoutGap="10px">
      <div class="loading-container mt-3 loading-grid">
        <div class="loading-content"></div>
      </div>
    </ng-container>
  </div>
  <div class="grid" *ngIf="!feedbackLoading">
    <div *ngFor="let singleFeedback of feedback" fxLayout="column" fxLayoutGap="10px" class="mt-3">
      <div fxLayout="column" class="mt-3">
        <div fxLayout="row">
          <app-feedback-stars [rating]="singleFeedback.rating"></app-feedback-stars>
          <span class="pl-2"
                *ngIf="singleFeedback.lastNameFirstLetter == null">{{singleFeedback.firstName}}</span>
          <span class="pl-2"
                *ngIf="singleFeedback.lastNameFirstLetter != null">{{singleFeedback.firstName}} {{singleFeedback.lastNameFirstLetter}}
            .</span></div>
        <div class="text-grey isu-text-fontsize-14">{{singleFeedback.formatTitle}}
          am {{singleFeedback.seminarDate | date: 'dd.MM.yy'}}</div>
        <div class="comment">
          <span *ngIf="singleFeedback.comment">&bdquo;{{singleFeedback.comment}}&ldquo;</span>
          <span *ngIf="!singleFeedback.comment"
                class="no-comment-available">Es wurde kein Kommentar hinterlassen</span>
        </div>
        <div *ngIf="singleFeedback.feedbackResponse != null" class="response-box" fxLayout="column"
             fxLayoutAlign="start start">
          <div class="responseFooter"> Unser Team hat diese Bewertung
            am {{singleFeedback.feedbackResponseDate | date: 'dd.MM.yy'}} beantwortet:
          </div>
          <p>{{singleFeedback.feedbackResponse}}</p>
        </div>
      </div>
    </div>
  </div>
  <hid-loading-button *ngIf="!feedbackLoading" color="primary" class="text-center m-auto pr-3 isu-padding"
                      [raised]="true" buttonClass="hid-button primary" (clickEvent)="loadFeedbackDataPage(page)"
                      [disabledCondition]="feedbackLoading || totalElements == feedback.length">Mehr Feedbacks laden
  </hid-loading-button>
</div>
