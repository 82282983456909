import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SemCheckoutS4LocationComponent} from './sem-checkout-s4-location/sem-checkout-s4-location.component';
import {SemCheckoutS4DatesComponent} from './sem-checkout-s4-dates/sem-checkout-s4-dates.component';
import {SemCheckoutS4UserComponent} from './sem-checkout-s4-user/sem-checkout-s4-user.component';
import {RouterModule, Routes} from '@angular/router';
import {SemCheckoutS4MainComponent} from './sem-checkout-s4-main/sem-checkout-s4-main.component';
import {BrowserModule} from '@angular/platform-browser';
import {FlexLayoutModule, FlexModule} from '@angular/flex-layout';
import {HidInteractionsModule} from '../../../hid-interactions/hid-interactions.module';
import {MatPaginatorModule} from '@angular/material/paginator';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ShareModule} from '../../../share/share.module';
import {GlobalModule} from '../../../global/global.module';
import {NgxJsonLdModule} from 'ngx-json-ld';
import {ActionLinkModule} from '../../../actionLinks/action-link/action-link.module';
import {FeedbackStarsModule} from '../../../feedback-stars/feedback-stars.module';
import {AccountModule} from '../../../account/account.module';
import {DiscountsModule} from '../../../discounts/discounts.module';
import {MatButtonModule} from '@angular/material/button';
import {SeminarCheckoutS4EntryComponent} from './seminar-checkout-s4-entry/seminar-checkout-s4-entry.component';
import {
  GlobalCheckoutComponentsModule
} from '../../components/global-checkout-components/global-checkout-components.module';


const appRoutes: Routes = [
  {path: 'angebote/:routingUrl/checkout/seminars/s4/entry', component: SeminarCheckoutS4EntryComponent},
  {
    path: 'angebote/:routingUrl/checkout/seminars/s4', component: SemCheckoutS4MainComponent,
    children: [
      {path: 'location', component: SemCheckoutS4LocationComponent},
      {path: 'date', component: SemCheckoutS4DatesComponent},
      {path: 'user', component: SemCheckoutS4UserComponent},
    ]
  }];

@NgModule({
  declarations: [
    SemCheckoutS4DatesComponent,
    SemCheckoutS4UserComponent,
    SemCheckoutS4MainComponent,
    SeminarCheckoutS4EntryComponent,
    SemCheckoutS4LocationComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(appRoutes),
    BrowserModule,
    FlexModule,
    HidInteractionsModule,
    MatPaginatorModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    ShareModule,
    GlobalModule,
    NgxJsonLdModule,
    ActionLinkModule,
    FeedbackStarsModule,
    AccountModule,
    DiscountsModule,
    MatButtonModule,
    GlobalCheckoutComponentsModule,
  ]
})
export class SemCheckoutS4Module { }
