import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../../api-resource";
import {JsonWrappedValue, LinkHubPageData} from "../data"

/**
 * This resource class was generated from Controller net.innospire.hidcms.controllers.secured.InternalLinkHubController
 */

@Injectable()
@ResourceParams({})
export class InternalLinkHubResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/internal/linkhub/create',
        endpoint: 'cms'
    })
    private _createPage: IResourceMethodObservableStrict<LinkHubPageData, {}, {}, LinkHubPageData>;
    createPage(requestBody: LinkHubPageData): Promise<LinkHubPageData> {
        return this._createPage(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/internal/linkhub/preview',
        endpoint: 'cms'
    })
    private _createTemporarySplitPreview: IResourceMethodObservableStrict<LinkHubPageData, {}, {}, JsonWrappedValue<string>>;
    createTemporarySplitPreview(requestBody: LinkHubPageData): Promise<string> {
        return this._createTemporarySplitPreview(requestBody, null, null).toPromise().then((result) => result.value)
    }

    @ResourceAction({
        method: ResourceRequestMethod.Delete,
        path: '/internal/linkhub/{id}/delete',
        endpoint: 'cms'
    })
    private _deletePage: IResourceMethodObservableStrict<void, {}, {id: number}, void>;
    deletePage(id: number): Promise<void> {
        return this._deletePage(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/internal/linkhub/{id}/duplicate',
        endpoint: 'cms'
    })
    private _duplicatePage: IResourceMethodObservableStrict<void, {}, {id: number}, LinkHubPageData>;
    duplicatePage(id: number): Promise<LinkHubPageData> {
        return this._duplicatePage(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/internal/linkhub/all',
        endpoint: 'cms'
    })
    private _getAllPages: IResourceMethodObservableStrict<void, {}, {}, LinkHubPageData[]>;
    getAllPages(): Promise<LinkHubPageData[]> {
        return this._getAllPages(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/internal/linkhub/{id}',
        endpoint: 'cms'
    })
    private _getPageByRoutingUrl: IResourceMethodObservableStrict<void, {}, {id: number}, LinkHubPageData>;
    getPageByRoutingUrl(id: number): Promise<LinkHubPageData> {
        return this._getPageByRoutingUrl(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/internal/linkhub/{id}/update',
        endpoint: 'cms'
    })
    private _updatePage: IResourceMethodObservableStrict<LinkHubPageData, {}, {id: number}, LinkHubPageData>;
    updatePage(requestBody: LinkHubPageData, id: number): Promise<LinkHubPageData> {
        return this._updatePage(requestBody, null, {id: id}).toPromise()
    }

}