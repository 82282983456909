import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../../api-resource";
import {LinkHubPageData} from "../data"

/**
 * This resource class was generated from Controller net.innospire.hidcms.controllers.public.PublicLinkHubController
 */

@Injectable()
@ResourceParams({})
export class PublicLinkHubResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/linkhub/{routingUrl}',
        endpoint: 'cms'
    })
    private _getPageByRoutingUrl: IResourceMethodObservableStrict<void, {}, {routingUrl: string}, LinkHubPageData>;
    getPageByRoutingUrl(routingUrl: string): Promise<LinkHubPageData> {
        return this._getPageByRoutingUrl(null, null, {routingUrl: routingUrl}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/linkhub/preview/{id}',
        endpoint: 'cms'
    })
    private _getSplitPreview: IResourceMethodObservableStrict<void, {}, {id: string}, LinkHubPageData>;
    getSplitPreview(id: string): Promise<LinkHubPageData> {
        return this._getSplitPreview(null, null, {id: id}).toPromise()
    }

}