import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../../api-resource";
import {JsonWrappedValue, PerfectMatchCvData} from "../data"

/**
 * This resource class was generated from Controller de.syntacton.cronos.controller.externalv2.PerfectMatchApiControllerV2
 */

@Injectable()
@ResourceParams({})
export class PerfectMatchApiResourceV2 extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/apiv2/perfectMatch/{code}/cv/infos',
        endpoint: 'cronos'
    })
    private _getJobApplicationInfos: IResourceMethodObservableStrict<void, {}, {code: string}, PerfectMatchCvData>;
    getJobApplicationInfos(code: string): Promise<PerfectMatchCvData> {
        return this._getJobApplicationInfos(null, null, {code: code}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/apiv2/perfectMatch/{code}/cv/infos',
        endpoint: 'cronos'
    })
    private _submitJobApplication: IResourceMethodObservableStrict<PerfectMatchCvData, {}, {code: string}, void>;
    submitJobApplication(requestBody: PerfectMatchCvData, code: string): Promise<void> {
        return this._submitJobApplication(requestBody, null, {code: code}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/apiv2/perfectMatch/{code}/cv/upload',
        endpoint: 'cronos'
    })
    private _uploadCvFile: IResourceMethodObservableStrict<void, {file: File}, {code: string}, JsonWrappedValue<('SERVER_ERROR' | 'FILE_TYPE_NOT_ALLOWED' | 'SUCCESS')>>;
    uploadCvFile(code: string, requestParams: {file: File}): Promise<JsonWrappedValue<('SERVER_ERROR' | 'FILE_TYPE_NOT_ALLOWED' | 'SUCCESS')>> {
        return this._uploadCvFile(null, requestParams, {code: code}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/apiv2/perfectMatch/newContact/cv/upload',
        endpoint: 'cronos'
    })
    private _uploadCvFileForNewContact: IResourceMethodObservableStrict<void, {firstName: string, lastName: string, birthDate: Date, emailAddress: string, applicationLevel: ('Internship' | 'Consultant' | 'Trainee' | 'Other'), phoneNumber: string, studyEndDate: Date, zipCode: string, locationName: string, file?: File}, {}, void>;
    uploadCvFileForNewContact(requestParams: {firstName: string, lastName: string, birthDate: Date, emailAddress: string, applicationLevel: ('Internship' | 'Consultant' | 'Trainee' | 'Other'), phoneNumber: string, studyEndDate: Date, zipCode: string, locationName: string, file?: File}): Promise<void> {
        return this._uploadCvFileForNewContact(null, requestParams, null).toPromise()
    }

}