import {Injectable} from '@angular/core';
import {InternalCookieService} from '../internal-cookie.service';


export class GDPRConsent {
  constructor(
    public initial: boolean = true,
    public consent_ga: boolean = false,
    public consent_linkedIn: boolean = false,
    public consent_facebook: boolean = false,
    public consent_pinterest: boolean = false,
    public consent_google_ads: boolean = false,
    public consent_tiktok: boolean = false,
    public consent_google_oc: boolean = false,
    public consent_facebook_oc: boolean = false,
    public consent_hotjar: boolean = false,
    public consent_bing: boolean = false,
    public consent_reddit_ads: boolean = false,
    public consent_snapchat_ads: boolean = false,
    public consent_ipApi: boolean = false,
    public consent_piwik: boolean = false
  ) {
  }
}

@Injectable({
  providedIn: 'root'
})
export class GDPRConsentService {

  //initial = true means user was not asked yet
  consent: GDPRConsent = new GDPRConsent();

  constructor(private cookieService: InternalCookieService) {
    //Just made the try catch because if you update the GDPRConsent-class old stored consents might produce issues
    try {
      let existingConsent = cookieService.getGDPRConsent();
      if (existingConsent != null) {
        this.consent = existingConsent;
        if (this.consent.consent_tiktok) {
          this.addTikTokScriptToHead();
        }
      }
    } catch (e) {
      //doesnt really matter, then we'll just need a new one
    }
  }

  updateGDPRConsent(consent: GDPRConsent) {
    //If tiktok is being enabled, add the script tag
    if (consent.consent_tiktok == true && !this.consent.consent_tiktok) {
      this.addTikTokScriptToHead();
    }
    //if tiktok is being disabled, remove the script tag
    if (consent.consent_tiktok == false && this.consent.consent_tiktok == true) {
      this.removeTikTokScriptFromHead();
    }
    this.consent = consent;
    this.cookieService.updateGDPRConsent(consent);

  }

  //The tiktok script in question cant be injected via GTM for some reason, so we need to insert in / remove from head depending on the users consent. There are two seperate scripts linked to different tiktok accounts or something.
  addTikTokScriptToHead() {
    //Add Script 1
    let node = document.createElement('script'); // creates the script tag
    node.src = '../assets/scripts/TikTokAnalyticsScript.js'; // sets the source
    node.type = 'text/javascript';
    node.async = true; // makes script run asynchronously
    node.charset = 'utf-8';
    // append to head of document
    document.getElementsByTagName('head')[0].appendChild(node);

    //Add Script 2
    let node_2 = document.createElement('script'); // creates the script tag
    node_2.src = '../assets/scripts/TikTokAnalyticsScript_2.js'; // sets the source
    node_2.type = 'text/javascript';
    node_2.async = true; // makes script run asynchronously
    node_2.charset = 'utf-8';
    // append to head of document
    document.getElementsByTagName('head')[0].appendChild(node_2);
  }

  removeTikTokScriptFromHead() {
    //It gets the ID in the TikTokAnalyticsScript.js

    //Remove Script 1
    let node = document.getElementById('tikTokHeadScript');
    if (node) {
      node.parentNode.removeChild(node);
    }

    //Remove Script 2
    let node_2 = document.getElementById('tikTokHeadScript_2');
    if (node_2) {
      node.parentNode.removeChild(node_2);
    }
  }
}
