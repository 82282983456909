<div *ngIf="!initialized" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="5px">
  <ng-container *ngFor="let i of Array(feedbackGroups.length-1).fill(1)">
    <div class="loading-container">
      <div class="loading-content"></div>
    </div>
  </ng-container>
</div>

<div *ngIf="initialized" fxLayout="row wrap" fxLayoutAlign="start center" class="filter" fxLayoutGap="5px">
  <a fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px" class="bubble"
     [ngStyle]="{'background-color': this.selectedSeminar == SeminarIdentifiers.All ? 'lightgrey' : 'white' }"
     routerLink="../{{SeminarIdentifiers.All}}">
    <div fxHide.lt-sm class="img allImg" role="img" alt="Logo Hochschulinitiative Deutschland"></div>
    <div fxLayout="column" fxLayoutAlign="start start">
      <span>Alle - <i class="fa fa-star star"></i> {{getGroup(SeminarIdentifiers.All).getAverageRating()}}</span>
      <div class="text-grey feedbackcount">{{getGroup(SeminarIdentifiers.All).getCompactCount()}} Erfahrungen</div>
    </div>
  </a>
  <a fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px" class="bubble"
     [ngStyle]="{'background-color': this.selectedSeminar == SeminarIdentifiers.Steuer ? 'lightgrey' : 'white' }"
     routerLink="../{{SeminarIdentifiers.Steuer}}">
    <div fxHide.lt-sm class="img steuerImg" role="img" alt="Steuerseminar Hochschulinitiative Deutschland"></div>
    <div fxLayout="column" fxLayoutAlign="start start">
      <span>Steuer - <i class="fa fa-star star"></i> {{getGroup(SeminarIdentifiers.Steuer).getAverageRating()}}</span>
      <div class="text-grey feedbackcount">{{getGroup(SeminarIdentifiers.Steuer).getCompactCount()}} Erfahrungen</div>
    </div>
  </a>
  <a fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px" class="bubble"
       [ngStyle]="{'background-color': this.selectedSeminar == SeminarIdentifiers.Thesis ? 'lightgrey' : 'white' }"
  routerLink="../{{SeminarIdentifiers.Thesis}}">
    <div fxHide.lt-sm class="img thesisImg" role="img" alt="Thesis-Seminar Hochschulinitiative Deutschland"></div>
    <div fxLayout="column" fxLayoutAlign="start start">
      <span>Thesis - <i class="fa fa-star star"></i> {{getGroup(SeminarIdentifiers.Thesis).getAverageRating()}}</span>
      <div class="text-grey feedbackcount">{{getGroup(SeminarIdentifiers.Thesis).getCompactCount()}} Erfahrungen</div>
    </div>
  </a>
  <a fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px" class="bubble"
       [ngStyle]="{'background-color': this.selectedSeminar == SeminarIdentifiers.Excel ? 'lightgrey' : 'white' }"
     routerLink="../{{SeminarIdentifiers.Excel}}">
    <div fxHide.lt-sm class="img excelImg" role="img" alt="Excel-Kurs Hochschulinitiative Deutschland"></div>
    <div fxLayout="column" fxLayoutAlign="start start">
      <span>Excel - <i class="fa fa-star star"></i> {{getGroup(SeminarIdentifiers.Excel).getAverageRating()}}</span>
      <div class="text-grey feedbackcount">{{getGroup(SeminarIdentifiers.Excel).getCompactCount()}} Erfahrungen</div>
    </div>
  </a>

  <a fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px" class="bubble"
     [ngStyle]="{'background-color': this.selectedSeminar == SeminarIdentifiers.Finance ? 'lightgrey' : 'white' }"
     routerLink="../{{SeminarIdentifiers.Finance}}">
    <div fxHide.lt-sm class="img financeImg" role="img" alt="Finanz-Kurs Hochschulinitiative Deutschland"></div>
    <div fxLayout="column" fxLayoutAlign="start start">
      <span>Finanzen - <i
        class="fa fa-star star"></i> {{getGroup(SeminarIdentifiers.Finance).getAverageRating()}}</span>
      <div class="text-grey feedbackcount">{{getGroup(SeminarIdentifiers.Finance).getCompactCount()}} Erfahrungen</div>
    </div>
  </a>
  <a fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px" class="bubble"
     [ngStyle]="{'background-color': this.selectedSeminar == SeminarIdentifiers.Fin4Women ? 'lightgrey' : 'white' }"
     routerLink="../{{SeminarIdentifiers.Fin4Women}}">
    <div fxHide.lt-sm class="img fin4womenImg" role="img" alt="Finanz-Kurs für Frauen Hochschulinitiative Deutschland"></div>
    <div fxLayout="column" fxLayoutAlign="start start">
      <span>FinEd 4 Women - <i
        class="fa fa-star star"></i> {{getGroup(SeminarIdentifiers.Fin4Women).getAverageRating()}}</span>
      <div class="text-grey feedbackcount">{{getGroup(SeminarIdentifiers.Fin4Women).getCompactCount()}} Erfahrungen</div>
    </div>
  </a>
</div>
