import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SeminarCheckoutS4ProcessService} from '../seminar-checkout-s4-process.service';

@Component({
  selector: 'app-seminar-checkout-s4-entry',
  templateUrl: './seminar-checkout-s4-entry.component.html',
  styleUrls: ['./seminar-checkout-s4-entry.component.sass']
})
export class SeminarCheckoutS4EntryComponent implements OnInit {


  constructor(private router: Router, private route: ActivatedRoute,
              private s4ProcessService: SeminarCheckoutS4ProcessService) {
  }


  ngOnInit(): void {
    //route to first step
    this.router.navigate([this.s4ProcessService.prepareSplitRoutingUrl(this.route, '/checkout/seminars/s4/location')]);
  }

}
