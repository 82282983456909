import {Pipe, PipeTransform} from '@angular/core';
import {SeminarIdentifier} from "./feedback-selector.service";

@Pipe({
  name: 'feedbackPageHeading'
})
export class FeedbackPageHeadingPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    switch(value) {
      case SeminarIdentifier.All:
        return "Was sagen Studierende zu unseren Seminaren? Hier findest du authentische und seriöse Bewertungen von\n" +
          "  Studierenden, die an unseren Online-Webinaren teilgenommen haben. Alle Teilnehmer können das Steuerseminar,\n" +
          "  Thesisseminar, Finanzseminar & Excelseminar mit 1 bis 5 Sternen bewerten und Kommentare hinterlassen. Alle\n" +
          "  Kommentare werden auf seriöse und rechtswidrige Inhalte geprüft, was die Veröffentlichung verzögern kann.";
      case SeminarIdentifier.Steuer:
        return "Hier findest du alle Erfahrungen von Studenten, die an unserem Steuerseminar teilgenommen haben um zu lernen,\n" +
          "        wie sie ihre erste Steuererklärung machen. Alle Teilnehmer können das Steuerseminar der Hochschulinitiative mit\n" +
          "        1 bis 5 Sternen bewerten und Kommentare hinterlassen. Alle Kommentare werden auf seriöse und rechtswidrige\n" +
          "        Inhalte geprüft, was die Veröffentlichung verzögern kann.";
      case SeminarIdentifier.Thesis:
        return "Hier findest du alle Erfahrungen von Studenten, die an unserem Thesisseminar teilgenommen haben und gelernt haben,\n" +
          "      wie sie ihre Thesis in kürzester Zeit mit einer Bestnote bestehen. Alle Teilnehmer können das Thesisseminar der\n" +
          "      Hochschulinitiative mit 1 bis 5 Sternen bewerten und Kommentare hinterlassen. Alle Kommentare werden auf seriöse\n" +
          "      und rechtswidrige Inhalte geprüft, was die Veröffentlichung verzögern kann.";
      case SeminarIdentifier.Finance:
        return "Hier findest du alle Erfahrungen von Studenten, die an unserem Finanzseminar teilgenommen haben und nun wissen,\n" +
          "      wie sie ihr Studenten-Budget smart sparen und investieren. Alle Teilnehmer können den Finanz-Crashkurs der\n" +
          "      Hochschulinitiative mit 1 bis 5 Sternen bewerten und Kommentare hinterlassen. Alle Kommentare werden auf seriöse\n" +
          "      und rechtswidrige Inhalte geprüft, was die Veröffentlichung verzögern kann.";
      case SeminarIdentifier.Fin4Women:
        return "Hier findest du alle Erfahrungen von Studentinnen, die an unserem Finanzseminar für Frauen teilgenommen haben und nun wissen,\n" +
          "      wie sie ihr Studenten-Budget smart sparen und investieren. Alle Teilnehmerinnen können den Finanz-Crashkurs der\n" +
          "      Hochschulinitiative mit 1 bis 5 Sternen bewerten und Kommentare hinterlassen. Alle Kommentare werden auf seriöse\n" +
          "      und rechtswidrige Inhalte geprüft, was die Veröffentlichung verzögern kann.";
      case SeminarIdentifier.Excel:
        return "Hier findest du alle Erfahrungen von Studenten, die an unserem Excelseminar teilgenommen und in 90 Minuten\n" +
          "        gelernt haben, wie sie Excel im Studium optimal nutzen können. Alle Teilnehmer können den Excel Kurs der\n" +
          "        Hochschulinitiative Deutschland mit 1 bis 5 Sternen bewerten und Kommentare hinterlassen. Alle Kommentare werden\n" +
          "        auf seriöse und rechtswidrige Inhalte geprüft, was die Veröffentlichung verzögern kann.";
      default: return null;
    }
  }

}


@Pipe({
  name: 'seminarHeader'
})
export class SeminarHeaderPipe implements PipeTransform{
  transform(value: any, ...args): any {
    switch(value){
      case SeminarIdentifier.All:
        return "unsere Seminare"
      case SeminarIdentifier.Steuer:
        return "unser Steuerseminar"
      case SeminarIdentifier.Thesis:
        return "unser Thesisseminar"
      case SeminarIdentifier.Excel:
        return "unser Excelseminar"
      case SeminarIdentifier.Finance:
        return "unser Finanzseminar"
      case SeminarIdentifier.Fin4Women:
        return "unser Finanzseminar für Frauen"
    }
  }
}

@Pipe({
  name: 'seminarFeedbackListHeader'
})
export class SeminarFeedbackListHeader implements PipeTransform{
  transform(value: any, ...args): any {
    switch(value){
      case SeminarIdentifier.All:
        return "zu den Seminaren"
      case SeminarIdentifier.Steuer:
        return "zum Steuerseminar"
      case SeminarIdentifier.Thesis:
        return "zum Thesisseminar"
      case SeminarIdentifier.Excel:
        return "zum Excelseminar"
      case SeminarIdentifier.Finance:
        return "zum Finanzseminar"
      case SeminarIdentifier.Fin4Women:
        return "zum Finanzseminar für Frauen"
    }
  }
}
