import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from "@angular/router";
import {PublicBundleResource, PublicOfferPageResource, StudentSessionResource} from "./generated/cms/resources";
import {MediaMatcher} from "@angular/cdk/layout";
import {LocalStorageService, SessionTracking} from "./local-storage.service";

import {MetaService} from "./meta.service";
import {AnalyticsService} from "./analytics/analytics.service";
import {TrackingService} from "./tracking.service";
import {GDPRConsentDialogService} from "./gdprconsent/gdprconsent-dialog.service";
import {GDPRConsentService} from "./gdprconsent/gdprconsent.service";
import {AuthService} from "./account/auth.service";
import {MediaService} from "./inno-utils/media.service";
import {PublicOfferBundleData, PublicOfferBundleMinimalData} from "./generated/cms/data";
import {OnlineWorkshopDropdownItemData} from './generated/cms/data/online-workshop-dropdown-item-data';
import {UtilsService} from "./utils.service";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {SeminarIdentifier} from "./feedback-overview/utils/feedback-selector.service";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'hid-plattform';

  mobileQuery: MediaQueryList;

  showSignin: boolean = false

  bundles: PublicOfferBundleMinimalData[] = [];
  showBundlesSpoiler = false;
  showOnlineBundleSpoiler = false;
  showQaSpoiler = false;

  navToggle = false;

  noScroll : boolean = false

  onlineSeminarBundle: PublicOfferBundleData;

  cookieDialogCurrentylOpen: boolean = false;

  private _mobileQueryListener: () => void;

  entryTrackingStorage = {
    utm_source: null,
    utm_campaign: null,
    utm_medium: null,
    utm_content: null,
    actuality: null,
    secondarySourceToken: null
  };

  showConsentPopup = false;

  constructor(changeDetectorRef: ChangeDetectorRef,
              media: MediaMatcher,
              private route: ActivatedRoute,
              public router: Router,
              private analytics: AnalyticsService,
              public sessionResource: StudentSessionResource,
              private publicBundleResource: PublicBundleResource,
              public localStorageService: LocalStorageService,
              private metaService: MetaService,
              private gdprConsentDialogService: GDPRConsentDialogService,
              private trackingService: TrackingService,
              private gdprConsentService: GDPRConsentService,
              public authService: AuthService,
              public mediaService: MediaService,
              private offerPageResource : PublicOfferPageResource,
              public utilService: UtilsService,
              private dialog: MatDialog,
              private dialogRef: MatDialogRef<any>
  ) {
    this.trackingService.initPreviousUrlTracking();

    this.analytics.initGtm();


    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();


    this.router.events.subscribe(evt => {
      if ((evt instanceof NavigationEnd)) {

        //if user wasnt asked for consent before, ask him unless he currently enjoys the privacy agreement
        if (this.router.url.indexOf('datenschutz') === -1 && this.gdprConsentService.consent.initial) {
          //"if statement" avoids the opening of multiple instances of the cookie dialog
          if(!this.cookieDialogCurrentylOpen){
            this.cookieDialogCurrentylOpen = true
            this.gdprConsentDialogService.openConsentDialog(false);
          }
        }
        if (this.router.url.match(new RegExp('webinar\\/([0-9]{4})\\/validate\\/([A-Z0-9_]{32})')) != null) {
          this.analytics.reportTripleOptInStatus(false, this.router.url.substring(this.router.url.lastIndexOf('/') + 1))
        }

        this.analytics.reportPageView()

        if (!evt.url.includes("/blog/") && !evt.url.includes("/angebote/")) {
          metaService.resetMetaTags();
          metaService.resetTitleTag();
        }

        window.scrollTo(0, 0);
      }
    });
  }

  refreshOnBackButtonClick(): void {
    this.router.events.subscribe((event: NavigationStart) => {
      if (event.navigationTrigger === 'popstate') {
        //window.location.reload();
      }
    });
  }

  ngOnInit() {

    //TODO this should be checked and be implemented in the near future
    //this.refreshOnBackButtonClick();


    this.route.queryParams.subscribe(p => {
      if(p['accountReferralCode']) {
        this.localStorageService.setAccountReferralCode(p['accountReferralCode'].toString())
      }
      this.localStorageService.pushTrackedClick(
        new SessionTracking(
          p['utm_source'] ? p['utm_source'].toString() : null,
          p['utm_content'] ? p['utm_content'].toString() : null,
          p['utm_medium'] ? p['utm_medium'].toString() : null,
          p['utm_campaign'] ? p['utm_campaign'].toString() : null,
          p['gclid'] ? p['gclid'].toString() : null,
          p['fbclid'] ? p['fbclid'].toString() : null,
          p['cutm_adacc'] ? p['cutm_adacc'].toString() : null
        )
      );
    });
    this.handShake();
    //Toggle after nav


    this.metaService.setTitleTagForHomepage()
    this.loadBundlesForNav();

    var botPattern = "(googlebot\/|bot|Googlebot-Mobile|Googlebot-Image|Google favicon|Mediapartners-Google|bingbot|slurp|java|wget|curl|Commons-HttpClient|Python-urllib|libwww|httpunit|nutch|phpcrawl|msnbot|jyxobot|FAST-WebCrawler|FAST Enterprise Crawler|biglotron|teoma|convera|seekbot|gigablast|exabot|ngbot|ia_archiver|GingerCrawler|webmon |httrack|webcrawler|grub.org|UsineNouvelleCrawler|antibot|netresearchserver|speedy|fluffy|bibnum.bnf|findlink|msrbot|panscient|yacybot|AISearchBot|IOI|ips-agent|tagoobot|MJ12bot|dotbot|woriobot|yanga|buzzbot|mlbot|yandexbot|purebot|Linguee Bot|Voyager|CyberPatrol|voilabot|baiduspider|citeseerxbot|spbot|twengabot|postrank|turnitinbot|scribdbot|page2rss|sitebot|linkdex|Adidxbot|blekkobot|ezooms|dotbot|Mail.RU_Bot|discobot|heritrix|findthatfile|europarchive.org|NerdByNature.Bot|sistrix crawler|ahrefsbot|Aboundex|domaincrawler|wbsearchbot|summify|ccbot|edisterbot|seznambot|ec2linkfinder|gslfbot|aihitbot|intelium_bot|facebookexternalhit|yeti|RetrevoPageAnalyzer|lb-spider|sogou|lssbot|careerbot|wotbox|wocbot|ichiro|DuckDuckBot|lssrocketcrawler|drupact|webcompanycrawler|acoonbot|openindexspider|gnam gnam spider|web-archive-net.com.bot|backlinkcrawler|coccoc|integromedb|content crawler spider|toplistbot|seokicks-robot|it2media-domain-crawler|ip-web-crawler.com|siteexplorer.info|elisabot|proximic|changedetection|blexbot|arabot|WeSEE:Search|niki-bot|CrystalSemanticsBot|rogerbot|360Spider|psbot|InterfaxScanBot|Lipperhey SEO Service|CC Metadata Scaper|g00g1e.net|GrapeshotCrawler|urlappendbot|brainobot|fr-crawler|binlar|SimpleCrawler|Livelapbot|Twitterbot|cXensebot|smtbot|bnf.fr_bot|A6-Indexer|ADmantX|Facebot|Twitterbot|OrangeBot|memorybot|AdvBot|MegaIndex|SemanticScholarBot|ltx71|nerdybot|xovibot|BUbiNG|Qwantify|archive.org_bot|Applebot|TweetmemeBot|crawler4j|findxbot|SemrushBot|yoozBot|lipperhey|y!j-asr|Domain Re-Animator Bot|AddThis)";
    var re = new RegExp(botPattern, 'i');
    var userAgent = navigator.userAgent;
    if (re.test(userAgent)) {
      this.navToggle = true
      this.showOnlineBundleSpoiler = true
      this.showQaSpoiler = true
      this.showBundlesSpoiler = true
    }
    else{
      this.router.events.subscribe(event => {
        this.navToggle = false;
        this.showOnlineBundleSpoiler = false
        this.showQaSpoiler = false
        this.showBundlesSpoiler = false
      });
    }

    this.utilService.registerDialogChange().subscribe(dialogIsOpen => {
      if (dialogIsOpen) {
        this.noScroll = true
      }
      else{
        this.noScroll = false
      }
    });
  }

  navToTalentAgent(utmMedium:string) {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
      this.router.navigate(["angebote/karriereplattform?utm_source=hidpage&utm_medium=navbar"]));
  }

  onlineSeminars: OnlineWorkshopDropdownItemData[] = []
  loadingBundles: boolean = false

  loadBundlesForNav() {
    this.loadingBundles = true
    this.publicBundleResource.getBundlesForOffersDropdown().then(res => {
      this.bundles = res;
      this.publicBundleResource.getBundleById(9330).then(res => {
        this.onlineSeminarBundle = res
        this.publicBundleResource.getOffersForOnlineWorkshopDropdown().then(res => {
          this.onlineSeminars = res
          this.loadingBundles = false
        });
      })
    })
  }

  //Says hello, gives noteable info for session to backend and implicitly sets or updates sessioncookie
  handShake() {
    this.sessionResource.performSessionHandshake()
  }

  protected readonly SeminarIdentifier = SeminarIdentifier;
}
