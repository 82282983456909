<div class="cookie-bar-backdrop"  fxLayout="column" fxLayoutAlign="center center"
     fxLayoutAlign.xs="end center">
  <div class="cookie-bar-wrapper-x">
    <div fxLayout="column" fxLayoutAlign="space-between start" class="height-100" >
      <h4>Cookies akzeptieren <i class="fa fa-cookie-bite"></i></h4>

      <div *ngIf="!detailedView" fxLayout="column" fxLayoutAlign="space-between start">
      <div class="cookie-bar-content">
        Wir nutzen Cookies, Pixel und vergleichbare Technologien, auch von Dritten, um unsere Dienste anzubieten, stetig
        zu verbessern und
        Inhalte sowie Werbeanzeigen personalisiert auf unserer Website, Social Media und Partnerseiten anzuzeigen. Mehr
        erfährst du in
        unserer Datenschutzerklärung. Mit Klick auf „Akzeptieren“ willigst du in die Verwendung dieser Technologien ein.
        Deine Einwilligung kannst du jederzeit auf der <a class="secondary" target="_blank" (click)="routePrivacy()">Datenschutzerklärungsseite</a>
        widerrufen.
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between end" class="actions" >
        <button class="hid-button secondary inverse ita_gdpr-more-info" (click)="detailedView = true">
          Mehr Infos
        </button>
        <button  class="hid-button primary ita_gdpr-confirm-all" (click)="submitAll()">
          Akzeptieren
        </button>
      </div>

    </div>
      <div *ngIf="detailedView">
          <div style="font-size: 14px">Gib an, mit der Nutzung welcher Tools du dich einverstanden erklärst. Nähere Informationen zu den einzelnen Komponenten findest du in unserer <a class="link-classic" (click)="routePrivacy()">Datenschutzerklärung</a>.</div>
        <div style="font-size: 14px">Danke, dass du uns bei der Verbesserung unserer Dienste unterstützt!</div>
        <hid-checkbox [control]="form.controls.consent_facebook">Facebook Pixel</hid-checkbox>
        <hid-checkbox [control]="form.controls.consent_ga">Google Analytics</hid-checkbox>
        <hid-checkbox [control]="form.controls.consent_google_ads">Google Ads</hid-checkbox>
        <hid-checkbox [control]="form.controls.consent_linkedIn">LinkedIn Pixel</hid-checkbox>
        <hid-checkbox [control]="form.controls.consent_pinterest">Pinterest Ads</hid-checkbox>
        <hid-checkbox [control]="form.controls.consent_tiktok">TikTok Pixel</hid-checkbox>
        <hid-checkbox [control]="form.controls.consent_google_oc">Google Offline-Conversions</hid-checkbox>
        <hid-checkbox [control]="form.controls.consent_facebook_oc">Facebook Offline-Conversions</hid-checkbox>
        <hid-checkbox [control]="form.controls.consent_hotjar">Hotjar</hid-checkbox>
        <hid-checkbox [control]="form.controls.consent_bing">Microsoft Bing</hid-checkbox>
        <hid-checkbox [control]="form.controls.consent_ipApi">Ip-Api</hid-checkbox>
        <hid-checkbox [control]="form.controls.consent_reddit_ads">Reddit Ads</hid-checkbox>
        <hid-checkbox [control]="form.controls.consent_snapchat_ads">Snapchat Ads</hid-checkbox>
        <hid-checkbox [control]="form.controls.consent_piwik">Piwik PRO</hid-checkbox>



        <div fxLayout="row" fxLayoutAlign="end end" class="actions" >
          <button  class="hid-button secondary inverse ita_gdpr-select-all" (click)="selectAll()">
            Alles wählen
          </button>
          <button  class="hid-button primary ita_gdpr-confirm-selection" (click)="submit()">
            Übernehmen

            <i class="far fam fa-smile" *ngIf="this.form.controls.consent_ga.value && this.form.controls.consent_facebook.value && this.form.controls.consent_linkedIn.value && this.form.controls.consent_pinterest.value && this.form.controls.consent_tiktok.value && this.form.controls.consent_google_oc.value && this.form.controls.consent_facebook_oc.value && this.form.controls.consent_hotjar.value"></i>
          </button>
        </div>
      </div>
    </div>
<!--    <div style="height: 30px;" *ngIf="mediaService.deviceIsCancerous()">-->
      <!--IOS overlays controls with controls of external applications -->
<!--    </div>-->
  </div>

</div>
