import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../../api-resource";
import {JsonWrappedValue, FormatPageFolderCreationData, FormatPageFolderData} from "../data"

/**
 * This resource class was generated from Controller net.innospire.hidcms.controllers.secured.InternalFormatPageFolderController
 */

@Injectable()
@ResourceParams({})
export class InternalFormatPageFolderResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/internal/offerPages/folders/{id}',
        endpoint: 'cms'
    })
    private _createNewFolder: IResourceMethodObservableStrict<FormatPageFolderCreationData, {}, {id: number}, JsonWrappedValue<number>>;
    createNewFolder(requestBody: FormatPageFolderCreationData, id: number): Promise<number> {
        return this._createNewFolder(requestBody, null, {id: id}).toPromise().then((result) => result.value)
    }

    @ResourceAction({
        method: ResourceRequestMethod.Delete,
        path: '/internal/offerPages/folders/{id}',
        endpoint: 'cms'
    })
    private _deleteFolder: IResourceMethodObservableStrict<void, {deleteContents: boolean}, {id: number}, void>;
    deleteFolder(id: number, requestParams: {deleteContents: boolean}): Promise<void> {
        return this._deleteFolder(null, requestParams, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/internal/offerPages/folders/{id}',
        endpoint: 'cms'
    })
    private _getFormatFolder: IResourceMethodObservableStrict<void, {nameQuery?: string}, {id: number}, FormatPageFolderData>;
    getFormatFolder(id: number, requestParams?: {nameQuery?: string}): Promise<FormatPageFolderData> {
        return this._getFormatFolder(null, requestParams, {id: id}).toPromise()
    }

}