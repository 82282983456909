import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormControl, ValidatorFn, Validators,} from '@angular/forms';
import {UtilsService} from '../../utils.service';
import {BehaviorSubject} from 'rxjs';
import {PhoneNumber, PhoneNumberUtil} from 'google-libphonenumber';

@Component({
  selector: 'app-hid-input-phone-number',
  templateUrl: './hid-input-phone-number.component.html',
  styleUrls: ['./hid-input-phone-number.component.scss']
})
export class HidInputPhoneNumberComponent implements OnInit {

  subControlValueChangedOnce = false;
  blockCountryCodeDropdownClose: boolean;
  showCountryCodeDropdown: boolean;
  phoneUtil = PhoneNumberUtil.getInstance();

  @Input() control: AbstractControl;
  @Input() checkoutAttempted: BehaviorSubject<boolean>;
  @Input() showValidatorIcons: boolean;
  @Input() inputFieldClass: string = '';
  @Input() disabled: boolean;
  @Input() name: string;
  @Input() label: string;
  @Input() type: string;

  selectedEUCountryCode: string = 'de';

  subControl: FormControl = new FormControl(null,
    {validators: [this.subValidator(), Validators.required]}
  );

  euCountries: EUCountry[] = [
    {code: 'be', name: 'Belgien'},
    {code: 'bg', name: 'Bulgarien'},
    {code: 'dk', name: 'Dänemark'},
    {code: 'de', name: 'Deutschland'},
    {code: 'ee', name: 'Estland'},
    {code: 'fi', name: 'Finnland'},
    {code: 'fr', name: 'Frankreich'},
    {code: 'gr', name: 'Griechenland'},
    {code: 'ie', name: 'Irland'},
    {code: 'it', name: 'Italien'},
    {code: 'hr', name: 'Kroatien'},
    {code: 'lv', name: 'Lettland'},
    {code: 'lt', name: 'Litauen'},
    {code: 'lu', name: 'Luxemburg'},
    {code: 'mt', name: 'Malta'},
    {code: 'nl', name: 'Niederlande'},
    {code: 'at', name: 'Österreich'},
    {code: 'pl', name: 'Polen'},
    {code: 'pt', name: 'Portugal'},
    {code: 'ro', name: 'Rumänien'},
    {code: 'se', name: 'Schweden'},
    {code: 'sk', name: 'Slowakei'},
    {code: 'si', name: 'Slowenien'},
    {code: 'es', name: 'Spanien'},
    {code: 'cz', name: 'Tschechien'},
    {code: 'hu', name: 'Ungarn'},
    {code: 'cy', name: 'Zypern'}
  ];

  constructor(
    public utilsService: UtilsService
  ) {
  }


  ngOnInit(): void {
    let subControlSub = this.subControl.valueChanges.subscribe(() => {
      subControlSub.unsubscribe();
      this.subControlValueChangedOnce = true;
    });

    this.subControl.valueChanges.subscribe(res => {
      if (res == null) {
        return;
      }
      this.control.setValue(this.buildPhoneNumber(this.subControl.value));
    });

    if (this.control.value) {
      this.initSubControlWithControlInput();
    } else {
      let sub = this.control.valueChanges.subscribe(() => {
        sub.unsubscribe();
        if (!this.subControlValueChangedOnce) {
          this.initSubControlWithControlInput();
        }
      });
    }
  }

  initSubControlWithControlInput(): void {
    let parsedPhoneNumber = this.parseSafeAndKeepRawInput(this.control.value);
    if (parsedPhoneNumber != null) {
      let regionCodeFromApi = this.phoneUtil.getRegionCodeForNumber(parsedPhoneNumber);
      if (regionCodeFromApi) {
        this.selectedEUCountryCode = regionCodeFromApi;
      }
      this.subControl.setValue(parsedPhoneNumber.getNationalNumber().toString());
    } else {
      this.subControl.setValue(this.control.value);
    }
  }

  subValidator(): ValidatorFn {
    return (subControl: AbstractControl): { [key: string]: any } | null => {
      if (subControl.value == undefined) {
        return null;
      }
      let phoneNumber = this.buildPhoneNumber(subControl.value);
      if (this.utilsService.checkPhoneNumberReturnErrors(phoneNumber) !== null) {
        return {isNotPhoneNumber: true};
      }
      return null;
    };
  }

  buildPhoneNumber(subControlNumber: string): string {
    let phoneNumber;
    if (subControlNumber.startsWith('00')) {
      this.subControl.setValue(this.subControl.value.replace('00', '+'));
    }
    let parsedPhoneNumber = this.parseSafeAndKeepRawInput(subControlNumber);
    if (parsedPhoneNumber != null) {
      let regionCodeFromApi = this.phoneUtil.getRegionCodeForNumber(parsedPhoneNumber);
      if (regionCodeFromApi) {
        this.selectedEUCountryCode = regionCodeFromApi;
      }
      phoneNumber = subControlNumber;
    } else {
      let selectedCountryCodeNumber = this.phoneUtil.getCountryCodeForRegion(this.selectedEUCountryCode);
      phoneNumber = '+' + selectedCountryCodeNumber + subControlNumber;
    }
    return phoneNumber;
  }

  parseSafeAndKeepRawInput(number): PhoneNumber {
    try {
      return this.phoneUtil.parseAndKeepRawInput(number);
    } catch (e) {
      return null;
    }
  }

  handleCountryCodeDropdown() {
    this.blockCountryCodeDropdownClose = true;
    this.showCountryCodeDropdown = !this.showCountryCodeDropdown;
    setInterval(() => {
      this.blockCountryCodeDropdownClose = false;
    }, 500);
  }

  onClickOutside($event: any) {
    if (this.blockCountryCodeDropdownClose) {
      return;
    }
    this.showCountryCodeDropdown = false;
  }

  onClickCode(countryCode: string) {
    this.selectedEUCountryCode = countryCode;
    if (this.blockCountryCodeDropdownClose) {
      return;
    }
    this.showCountryCodeDropdown = false;

    let parsedPhoneNumber = this.parseSafeAndKeepRawInput(this.subControl.value);
    if (parsedPhoneNumber != null) {
      this.subControl.setValue(parsedPhoneNumber.getNationalNumber().toString());
    }

    this.subControl.updateValueAndValidity();
  }
}

export interface EUCountry {
  code: string,
  name: string
}
