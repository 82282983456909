import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {SeminarCheckoutService} from "../../seminar-checkout.service";
import {GlobalCheckoutService} from "../../../global-checkout.service";

@Component({
  selector: 'app-sem-checkout4-main',
  templateUrl: './sem-checkout-s4-main.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./sem-checkout-s4-main.component.scss']
})
export class SemCheckoutS4MainComponent implements OnInit,OnDestroy {


  constructor(private globalCheckoutService: GlobalCheckoutService, private seminarCheckoutService: SeminarCheckoutService) { }

  ngOnInit(): void {

  }

  ngOnDestroy() {
    //Forewarding will get disabled once a step is reached, that requires input. However, if you just leave the checkout, the forewarding should be enabled again for the next time
    this.globalCheckoutService.forewardingEnabled = true
  }
}
