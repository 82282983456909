import {Component, OnInit} from '@angular/core';
import {EnterPromotionContactData} from '../../../../generated/cronos/data';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BehaviorSubject} from 'rxjs';
import {UtilsService} from '../../../../utils.service';
import {LocalStorageService} from '../../../../local-storage.service';
import {PromotionApiResourceV2, PublicStructureResourceV2} from '../../../../generated/cronos/resources';
import {ActivatedRoute, Router} from '@angular/router';
import {GlobalCheckoutService} from '../../../global-checkout.service';
import {SeminarCheckoutService} from '../../seminar-checkout.service';
import {PublicFormatPageData} from '../../../../generated/cms/data';
import {SeminarCheckoutS4ProcessService} from '../seminar-checkout-s4-process.service';

@Component({
  selector: 'app-sem-checkout-s4-user',
  templateUrl: './sem-checkout-s4-user.component.html',
  styleUrls: ['./sem-checkout-s4-user.component.scss']
})
export class SemCheckoutS4UserComponent implements OnInit {


  contactForm: FormGroup;

  page: PublicFormatPageData;
  user: EnterPromotionContactData


  constructor(private fb: FormBuilder,
              private utilsService: UtilsService,
              public localStorageService: LocalStorageService,
              private publicStructureResource: PublicStructureResourceV2,
              private promotionApiResourceV2: PromotionApiResourceV2,
              public globalCheckoutService: GlobalCheckoutService,
              public seminarCheckoutService: SeminarCheckoutService,
              private router: Router,
              private s4ProcessService: SeminarCheckoutS4ProcessService,
              private route: ActivatedRoute
  ) {
    this.contactForm = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.email, Validators.required]],
      acceptPrivacy: ['', Validators.requiredTrue],
      acceptAdditionalTerms: [''],
      studyEndYear: [''],
      phoneNumber: [null, [this.utilsService.validatePhoneNumber_split1(), Validators.required]]
    });
  }

  checkoutAttempted = new BehaviorSubject<boolean>(false);
  showConditionsOfParticipations = false;
  ngOnInit() {
    this.s4ProcessService.reportTrackingEvent('ContactDataReached');

    this.page = this.globalCheckoutService.getCurrentPage()
    this.user = this.globalCheckoutService.getCurrentUser()
    this.globalCheckoutService.forewardingEnabled = false

    this.promotionApiResourceV2.getPromotionFormat(this.page.cronosFormatId).then(result => {

        this.showConditionsOfParticipations = result.showConditionsOfParticipation
      });
      window.scrollTo(0, 0);
      //If competition we need to display terms
      if (this.showConditionsOfParticipations) {
        this.contactForm.controls.acceptAdditionalTerms.setValidators([Validators.requiredTrue])
      }

      //Init the form with given contactdata
      this.contactForm.controls['firstName'].setValue(this.user.firstName);
      this.contactForm.controls['lastName'].setValue(this.user.lastName);
      this.contactForm.controls['email'].setValue(this.user.email);
      this.contactForm.controls['phoneNumber'].setValue(this.user.phoneNumber);
      this.contactForm.controls['studyEndYear'].setValue(this.user.studyEndYear);
    }

    checkingOut = false;
    attemptCheckout(){
      this.checkoutAttempted.next(true)
      if(!this.contactForm.valid)return
      this.globalCheckoutService.fillUserData(<EnterPromotionContactData>{
        firstName: this.contactForm.controls['firstName'].value,
        lastName: this.contactForm.controls['lastName'].value,
        email: this.contactForm.controls['email'].value,
        phoneNumber: this.contactForm.controls['phoneNumber'].value,
        studyEndYear: this.contactForm.controls['studyEndYear'].value,
        privacyConsentGiven: true //consent is mandatory input field
      })
      this.checkingOut = true;
      this.s4ProcessService.reportTrackingEvent('ContactDataCompleted');
      this.s4ProcessService.inCheckout = false
      this.s4ProcessService.ipRetrievedSuccessfully = false
      this.seminarCheckoutService.completeCheckout(this.s4ProcessService.splitIdentifier);
    }

    back(){
      this.seminarCheckoutService.clearSelectedSeminar()
      this.router.navigate([this.s4ProcessService.prepareSplitRoutingUrl(this.route.parent, 'checkout/seminars/s4/date')]);
    }

}
