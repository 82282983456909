import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../../api-resource";
import {JsonWrappedValue, TokenFeedbackData} from "../data"

/**
 * This resource class was generated from Controller de.syntacton.cronos.controller.externalv2.SeminarProcessAPIControllerV2
 */

@Injectable()
@ResourceParams({})
export class SeminarProcessAPIResourceV2 extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/apiv2/promotions/seminars/checkin/{checkInToken}',
        endpoint: 'cronos'
    })
    private _checkInContactToSeminarByCheckInToken: IResourceMethodObservableStrict<void, {}, {checkInToken: string}, void>;
    checkInContactToSeminarByCheckInToken(checkInToken: string): Promise<void> {
        return this._checkInContactToSeminarByCheckInToken(null, null, {checkInToken: checkInToken}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/apiv2/promotions/seminars/download/{downloadToken}',
        endpoint: 'cronos'
    })
    private _getSeminarMaterialByDownloadToken: IResourceMethodObservableStrict<void, {}, {downloadToken: string}, Blob>;
    getSeminarMaterialByDownloadToken(downloadToken: string): Promise<Blob> {
        return this._getSeminarMaterialByDownloadToken(null, null, {downloadToken: downloadToken}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/apiv2/promotions/seminars/markasparticipated/{feedbackToken}',
        endpoint: 'cronos'
    })
    private _markStudentAsParticipated: IResourceMethodObservableStrict<void, {}, {feedbackToken: string}, JsonWrappedValue<boolean>>;
    markStudentAsParticipated(feedbackToken: string): Promise<JsonWrappedValue<boolean>> {
        return this._markStudentAsParticipated(null, null, {feedbackToken: feedbackToken}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/apiv2/promotions/seminars/download/{downloadToken}/byEmail',
        endpoint: 'cronos'
    })
    private _sendSeminarMaterialByEmailToContactByToken: IResourceMethodObservableStrict<void, {}, {downloadToken: string}, TokenFeedbackData>;
    sendSeminarMaterialByEmailToContactByToken(downloadToken: string): Promise<TokenFeedbackData> {
        return this._sendSeminarMaterialByEmailToContactByToken(null, null, {downloadToken: downloadToken}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/apiv2/promotions/seminars/signOff/{checkInToken}',
        endpoint: 'cronos'
    })
    private _signContactOffBySeminarCheckInToken: IResourceMethodObservableStrict<void, {}, {checkInToken: string}, void>;
    signContactOffBySeminarCheckInToken(checkInToken: string): Promise<void> {
        return this._signContactOffBySeminarCheckInToken(null, null, {checkInToken: checkInToken}).toPromise()
    }

}