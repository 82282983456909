import {Component, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {AccountResource} from '../../generated/cronos/resources';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {

  token = null;
  validatingToken: boolean = false;
  tokenConfirmed: boolean;

  constructor(
    private accountResource: AccountResource,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.route.queryParamMap.subscribe(params => {
      this.token = params.get("passwordResetToken")
    })
  }

  ngOnInit(): void {
    if(this.token) {
      this.validatingToken = true
      this.accountResource.validatePasswordRequestTokenExists({tokenValue: this.token}).then(
        result => {
          this.tokenConfirmed = result.value;
          this.validatingToken = false;
        }
      )
    }
  }

  email = new FormControl('', [
    Validators.required,
    Validators.email
  ]);
  password = new FormControl("");

  request() {
    this.accountResource.requestPasswordReset({emailAddress: this.email.value})
  }

  reset() {
    this.accountResource.resetPassword({token: this.token, password: this.password.value})
      .then(() => {
          this.router.navigateByUrl("/account/login")
        }
      )
  }

}
