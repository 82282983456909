import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {
  ContactSupportModalComponent
} from '../../../contact-support-modal/contact-support-modal/contact-support-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute, Router} from '@angular/router';
import {ActionLinkAPIResourceV2, PromotionApiResourceV2} from '../../../generated/cronos/resources';
import {PublicSeminarData, RegistrationFeedbackData, SignupCodeFeedbackData} from '../../../generated/cronos/data';
import {PublicOfferPageResource} from '../../../generated/cms/resources';
import {LocalStorageService} from '../../../local-storage.service';
import {MediaService} from '../../../inno-utils/media.service';
import {PublicFormatPageOneClickSignUpData} from 'src/app/generated/cms/data';
import {GDPRConsentService} from '../../../gdprconsent/gdprconsent.service';
import {GlobalCheckoutService} from '../../../checkout/global-checkout.service';
import {Subject, Subscription} from 'rxjs';


@Component({
  selector: 'app-promotion-signup',
  templateUrl: './promotion-signup.component.html',
  styleUrls: ['./promotion-signup.component.scss']
})
export class PromotionSignupComponent implements OnInit, OnDestroy {

  token: string;

  iteminfo: SignupCodeFeedbackData = <SignupCodeFeedbackData>{};
  formatDescriptionData: PublicFormatPageOneClickSignUpData = <PublicFormatPageOneClickSignUpData>{};

  alternativeSeminars: PublicSeminarData[] = [];
  initialSelectedSeminar: PublicSeminarData;

  loading = false;
  loadingAlternativeSeminars = true;
  loadingDescription: boolean = true;
  submitting = false;
  redirecting = false;

  privacyConsentGiven = false;
  showPrivacyConsentError = false;

  selectedSeminar: PublicSeminarData;

  hidSplit: string = 'OneClick';

  promotionSubject: Subject<PublicSeminarData> = new Subject();
  promotionSubscription: Subscription;
  totalDisplayedSeminarsAmountSubject: Subject<number> = new Subject();
  seminarAmountsSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private modal: NgbModal,
    private router: Router,
    public mediaService: MediaService,
    private cronosApi: ActionLinkAPIResourceV2,
    private offerPageResource: PublicOfferPageResource,
    private cronosPromotionResource: PromotionApiResourceV2,
    private localStorageService: LocalStorageService,
    private gdprConsentService: GDPRConsentService,
    private globalCheckoutService: GlobalCheckoutService,
    @Inject('env') private env
  ) {

    route.snapshot.queryParamMap.get('code') ? this.token = route.snapshot.queryParamMap.get('code') : this.token = route.snapshot.paramMap.get('token');

    route.queryParams.subscribe(params => {
      if (params["preview"]) {
        this.buildPreview(params["fid"]);
      } else {
        this.getItemInfo();
      }
    })


  }

  ngOnDestroy() {
    if (this.promotionSubscription) {
      this.promotionSubscription.unsubscribe();
    }
    if (this.seminarAmountsSubscription) {
      this.seminarAmountsSubscription.unsubscribe();
    }
  }

  ngOnInit() {

  }

  getItemInfo() {
    this.loading = true;
    this.cronosApi.getEnterPromotionTokenInfo({
      code: this.token
    }).then(res => {
      this.iteminfo = res;
      if (this.iteminfo.publicContactData != null) {
        this.globalCheckoutService.updateCheckoutUserPublicContactData(this.iteminfo.publicContactData);
        this.globalCheckoutService.updateLocationOnUser(this.iteminfo.publicContactData.location)
      }
      this.loading = false;
      if (this.iteminfo.state == 'VALID') {
        this.loadFormatDescriptionFromCms();
      }
      if (this.iteminfo.registrationData.promotion.type == 'SingleWebinarDate') {
        this.getAlternativeSeminars();
      }
    });
  }

  buildPreview(formatId: number) {
    this.cronosPromotionResource.getPromotionFormat(formatId).then(res => {
      this.iteminfo.state = 'VALID';
      this.formatDescriptionData.averageRating = res.averageFeedbackRating

      this.cronosPromotionResource.getActivePromotionOfFormat(formatId).then(res => {
        this.iteminfo.registrationData = <RegistrationFeedbackData>{
          code: "previewDummyCode", promotion: res, status: "Success"
        }
        this.loadFormatDescriptionFromCms(formatId)
        if (res.type == "SingleWebinarDate") this.getPreviewSeminars(res.id)
      })
    })
  }

  reportReached(skip: boolean, displayedSeminarsAmount?: number) {
    let displayedAmountString = null;
    if (displayedSeminarsAmount) {
      displayedAmountString = displayedSeminarsAmount.toString();
    }
    this.globalCheckoutService.reportTrackingEvent('DatesReached',
      {
        skip: JSON.stringify(skip),
        numDateOptions: displayedAmountString,
        hidSplit: this.hidSplit,
        formatId: this.selectedSeminar.formatId.toString(),
        formatType: this.selectedSeminar.formatType,
        pageSpid: null,
        leadQualifier: this.selectedSeminar.leadQualifier,
        locationId: this.selectedSeminar.locationId.toString(),
        locationName: this.selectedSeminar.locationName
      });
  }

  getAlternativeSeminars() {
    this.loadingAlternativeSeminars = true;

    this.cronosPromotionResource.getLocalSeminarsByToken({
      token: this.token,
      numberOfSeminars: 4,

    }).then(res => {
      this.processSeminarData(res)

      this.seminarAmountsSubscription = this.totalDisplayedSeminarsAmountSubject.subscribe(value => {
        this.reportReached(false, value);
      });
    });
  }

  getPreviewSeminars(promId: number) {
    this.loadingAlternativeSeminars = true;
    this.cronosPromotionResource.getLocalSeminarsForPreview({
      promotionId: promId,
      numberOfSeminars: 4,
    }).then(res => {
      this.processSeminarData(res)
    })
  }

  processSeminarData(data: PublicSeminarData[]) {
    this.selectedSeminar = data.filter(seminar => seminar.id == this.iteminfo.registrationData.promotion.id)[0];
    this.alternativeSeminars = data;
    this.loadingAlternativeSeminars = false;
    this.promotionSubscription = this.promotionSubject.subscribe(value => {
      this.handleSeminarSelection(value);
    });
  }

  handleSeminarSelection(seminar: PublicSeminarData) {
    //track any date selection as event here or just on signup?
    if (this.selectedSeminar == seminar) {
      this.selectedSeminar = null;
      return;
    }
    this.selectedSeminar = seminar;
  }

  signUp() {
    this.submitting = true;

    if (this.privacyConsentGiven == false) {
      this.showPrivacyConsentError = true;
      this.submitting = false;
      return;
    }

    this.showPrivacyConsentError = false;

    let seminarId = null;
    if (this.selectedSeminar) {
      seminarId = this.selectedSeminar.id;
    }
    this.cronosApi.enterPromotionByOneClickSignup({
      seminarId: seminarId,
      privacyConsentGiven: true,
      googleOcConsent: this.gdprConsentService.consent.consent_google_oc,
      facebookOcConsent: this.gdprConsentService.consent.consent_facebook_oc,
      codeData: {
        sourceToken: this.env.cronosSourceToken,
        code: this.token
      }
    }).then(res => {
      this.globalCheckoutService.reportTrackingEvent('CheckoutCompleted', {hidSplit: this.hidSplit});
      this.iteminfo.state = res;
      this.submitting = false;
      if (this.iteminfo.state === 'VALID') {
        if (this.iteminfo.registrationData.promotion.type == 'OnlineLecture') {
          this.router.navigateByUrl('enter/onlineLecture/' + this.iteminfo.registrationData.promotion.formatId + '/validate/' + this.iteminfo.registrationData.code
            + '?fid=' + this.iteminfo.registrationData.promotion.formatId + '&sid=' + this.iteminfo.registrationData.promotion.id);
        } else if (['OfflineSeminarDate', 'SingleWebinarDate', 'MammothWebinarDate'].indexOf(this.iteminfo.registrationData.promotion.type) > -1) {
          this.router.navigateByUrl('/thank-you?fid=' + this.iteminfo.registrationData.promotion.formatId + '&sid=' + this.iteminfo.registrationData.promotion.id);
        } else {
          this.router.navigateByUrl('/thank-you?fid=' + this.iteminfo.registrationData.promotion.formatId);
        }
      }
    });
  }

  loadFormatDescriptionFromCms(forcedFormatId = null) {
    this.loadingDescription = true;
    this.offerPageResource.getFormatPageDescriptionsBySyncedFormatId({syncedFormatId: forcedFormatId ? forcedFormatId : this.iteminfo.registrationData.promotion.formatId}).then(
      result => {
        this.formatDescriptionData = result;

        this.formatDescriptionData.ctaDescription = result.ctaDescription? result.ctaDescription : "";
        this.formatDescriptionData.ctaText = result.ctaText? result.ctaText : "Jetzt Anmelden";

        if (this.formatDescriptionData.averageRating > 4) {
          this.formatDescriptionData.averageRating = Math.round(this.formatDescriptionData.averageRating * 2) / 2;
        } else {
          this.formatDescriptionData.averageRating = this.calculateRating();
        }
        this.loadingDescription = false;
      }
    );
  }

  calculateRating(): number {
    var rating = 0.0;
    const mod = this.formatDescriptionData.id % 3;
    switch (mod) {
      case 0:
        rating = 4.0;
        break;
      case 1:
        rating = 4.5;
        break;
      case 2:
        rating = 5.0;
        break;
    }
    return rating;
  }

  redirectToOfferPage() {
    this.redirecting = true;
    this.offerPageResource.getFormatPageForSyncedFormat({syncedFormatId: this.iteminfo.registrationData.promotion.formatId}).then(page => {
      this.router.navigate(['/angebote/', page.routingUrl], {queryParams: {instantCheckout: true}});
    });
  }

  goHome() {
    this.router.navigate(['home'], {queryParamsHandling: 'preserve'});
  }

  openSupportModal() {
    const modalRef = this.modal.open(ContactSupportModalComponent);
  }

}
