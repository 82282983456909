import {Component, OnDestroy, OnInit} from '@angular/core';
import {EnterPromotionContactData, PublicSeminarData} from '../../../../generated/cronos/data';
import {PublicFormatPageData} from '../../../../generated/cms/data';
import {PromotionApiResourceV2, PublicStructureResourceV2} from '../../../../generated/cronos/resources';
import {ActivatedRoute, Router} from '@angular/router';
import {InternalCookieService} from '../../../../internal-cookie.service';
import {AnalyticsService} from '../../../../analytics/analytics.service';
import {GlobalCheckoutService} from '../../../global-checkout.service';
import {SeminarCheckoutService} from '../../seminar-checkout.service';
import {SeminarCheckoutSFlavourProcessService} from '../seminar-checkout-sFlavour-process.service';
import {Subject, Subscription} from 'rxjs';

//if date options are less or equal than this, waitinglist will be offered to the user
const offerAlternativesUnderDatesAmount = 3;

@Component({
  selector: 'app-sem-checkout-sFlavour-dates',
  templateUrl: './sem-checkout-sFlavour-dates.component.html',
  styleUrls: ['./sem-checkout-sFlavour-dates.component.scss']
})
export class SemCheckoutSFlavourDatesComponent implements OnInit, OnDestroy {
  user: EnterPromotionContactData;
  dates: PublicSeminarData[] = [];

  offerWaitinglist: boolean = false;
  loading = true;

  page: PublicFormatPageData;

  promotionSubject: Subject<PublicSeminarData> = new Subject();
  promotionSubscription: Subscription;
  totalDisplayedSeminarsAmountSubject: Subject<number> = new Subject();
  seminarAmountsSubscription: Subscription;

  constructor(private cronosPromotionResource: PromotionApiResourceV2,
              public globalCheckoutService: GlobalCheckoutService,
              private seminarCheckoutService: SeminarCheckoutService,
              private router: Router,
              private internalCookieService: InternalCookieService,
              private analyticsService: AnalyticsService,
              private sFlavourProcessService: SeminarCheckoutSFlavourProcessService,
              private cronosStructureApi: PublicStructureResourceV2,
              private route: ActivatedRoute) {
  }

  ngOnDestroy() {
    if (this.promotionSubscription) {
      this.promotionSubscription.unsubscribe();
    }
    if (this.seminarAmountsSubscription) {
      this.seminarAmountsSubscription.unsubscribe();
    }
  }

  ngOnInit() {
    this.page = this.globalCheckoutService.getCurrentPage();
    this.user = this.globalCheckoutService.getCurrentUser();

    window.scrollTo(0, 0);

    if (this.globalCheckoutService.forewardingEnabled && this.seminarCheckoutService.getSeminar().id) {
      this.reportReached(true, null);
      this.next(true);
    } else {
      this.globalCheckoutService.forewardingEnabled = false;
      //this can occur in some situations when the user is coming back from the contactdata-step after having a preselected date and didnt have a location selected because he initially skipped the location step
      if (this.user.locationId == null) {
        this.back();
        return;
      }
      this.loadDates();
    }

  }

  reportReached(skip: boolean, displayedSeminarsAmount?: number) {
    let displayedAmountString = null;
    if (displayedSeminarsAmount) {
      displayedAmountString = displayedSeminarsAmount.toString();
    }
    this.sFlavourProcessService.reportTrackingEvent('DatesReached',
      {skip: JSON.stringify(skip), numDateOptions: displayedAmountString});
  }



  next(skip: boolean = false) {
    //if step gets skipped, selectDate will not be called so we fire here. Little bit dirty though..
    if (skip) {
      this.sFlavourProcessService.reportTrackingEvent('DateSelected', {skip: JSON.stringify(skip)});
    }
    this.router.navigate([this.sFlavourProcessService.prepareSplitRoutingUrl(this.route.parent, 'checkout/seminars/sFlavour/user')]);
  }

  back() {
    this.globalCheckoutService.clearLocation();
    this.router.navigate([this.sFlavourProcessService.prepareSplitRoutingUrl(this.route.parent, 'checkout/seminars/sFlavour/location')]);
  }

  selectDate(date: PublicSeminarData) {
    this.seminarCheckoutService.setSeminar(date);
    this.sFlavourProcessService.reportTrackingEvent('DateSelected', {promotionId: date.id.toString(), skip: 'false'});
    this.next();
  }

  loadDates() {
    let formatIds = [this.page.cronosFormatId];
    this.cronosPromotionResource.getSeminars({
      locationId: this.user.locationId,
      formatIds: formatIds,
      page: 0,
      pageSize: 200
    }).then(page => {
      this.dates = page;
      this.loading = false;
      this.promotionSubscription = this.promotionSubject.subscribe(value => {
        this.selectDate(value);
      });
      this.seminarAmountsSubscription = this.totalDisplayedSeminarsAmountSubject.subscribe(value => {
        this.reportReached(false, value);
      });
      let existingSubscription = this.internalCookieService.getExistingSeminarRegistration(this.page.cronosFormatId);
      if (existingSubscription.length > 0) {
        this.cronosPromotionResource.getAllowanceForMultipleRegistrationsForLocationAndSeminar(
          {locationId: this.user.locationId, formatId: this.page.cronosFormatId}
        ).then(result => {
          if (result.value == false) {
            this.dates = [];
          }
        });

      }

    });
  }

  waitingList() {
    this.seminarCheckoutService.waitingList = true;
    this.next();
  }
}
