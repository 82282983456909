import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../../api-resource";
import {JsonWrappedValue, EnterServiceFormData, PublicMammothWebinarData, PublicStudyAreaData, DisplayServiceFormData, PagedData, PublicUniversityData} from "../data"

/**
 * This resource class was generated from Controller de.syntacton.cronos.controller.externalv2.ServiceFormController
 */

@Injectable()
@ResourceParams({})
export class ServiceFormResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/apiv2/promotions/feedback/serviceform/enterbycode/{token}',
        endpoint: 'cronos'
    })
    private _enterServiceFormDataWithCode: IResourceMethodObservableStrict<EnterServiceFormData, {}, {token: string}, void>;
    enterServiceFormDataWithCode(requestBody: EnterServiceFormData, token: string): Promise<void> {
        return this._enterServiceFormDataWithCode(requestBody, null, {token: token}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/apiv2/promotions/feedback/serviceform/enterwithoutcode',
        endpoint: 'cronos'
    })
    private _enterServiceFormDataWithoutCode: IResourceMethodObservableStrict<EnterServiceFormData, {}, {}, void>;
    enterServiceFormDataWithoutCode(requestBody: EnterServiceFormData): Promise<void> {
        return this._enterServiceFormDataWithoutCode(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/apiv2/promotions/feedback/serviceform/studyareas',
        endpoint: 'cronos'
    })
    private _getAllStudyAreas: IResourceMethodObservableStrict<void, {}, {}, PublicStudyAreaData[]>;
    getAllStudyAreas(): Promise<PublicStudyAreaData[]> {
        return this._getAllStudyAreas(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/apiv2/promotions/feedback/mammothwebinar/{token}',
        endpoint: 'cronos'
    })
    private _getMammothWebinarByToken: IResourceMethodObservableStrict<void, {}, {token: string}, PublicMammothWebinarData>;
    getMammothWebinarByToken(token: string): Promise<PublicMammothWebinarData> {
        return this._getMammothWebinarByToken(null, null, {token: token}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/apiv2/promotions/feedback/serviceform/infobycode/{token}',
        endpoint: 'cronos'
    })
    private _getServiceFormInformationWithCode: IResourceMethodObservableStrict<void, {}, {token: string}, DisplayServiceFormData>;
    getServiceFormInformationWithCode(token: string): Promise<DisplayServiceFormData> {
        return this._getServiceFormInformationWithCode(null, null, {token: token}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/apiv2/promotions/feedback/serviceform/infowithoutcode',
        endpoint: 'cronos'
    })
    private _getServiceFormInformationWithoutCode: IResourceMethodObservableStrict<void, {}, {}, DisplayServiceFormData>;
    getServiceFormInformationWithoutCode(): Promise<DisplayServiceFormData> {
        return this._getServiceFormInformationWithoutCode(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/apiv2/promotions/feedback/serviceform/universities',
        endpoint: 'cronos'
    })
    private _getUniversitiesByQueryString: IResourceMethodObservableStrict<void, {q: string, page: number, pageSize: number, forcedIds: number[]}, {}, PagedData<PublicUniversityData>>;
    getUniversitiesByQueryString(requestParams: {q: string, page: number, pageSize: number, forcedIds: number[]}): Promise<PagedData<PublicUniversityData>> {
        return this._getUniversitiesByQueryString(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/apiv2/promotions/feedback/serviceform/trigger/{token}',
        endpoint: 'cronos'
    })
    private _triggerServiceFormEmails: IResourceMethodObservableStrict<void, {}, {token: string}, JsonWrappedValue<boolean>>;
    triggerServiceFormEmails(token: string): Promise<JsonWrappedValue<boolean>> {
        return this._triggerServiceFormEmails(null, null, {token: token}).toPromise()
    }

}