import {Injectable} from "@angular/core";
import {PromotionApiResourceV2} from "../../generated/cronos/resources";
import {FeedBackOverviewData} from "../../generated/cronos/data";


export enum SeminarIdentifier {
    Steuer,
    Thesis,
    Excel,
    Finance,
    Fin4Women,
    All
}

class FormatToIds {
    type: SeminarIdentifier
    ids: number[]
}

class FormatToFeedbackOverviewData{
    type: SeminarIdentifier
    feedbackData: FeedBackOverviewData
}

@Injectable({
    providedIn: 'root'
})
export class FeedbackSelectorService {

    formatToIds: FormatToIds[] = [
        {type: SeminarIdentifier.Steuer, ids: [1938, 2214]},
        {type: SeminarIdentifier.Thesis, ids: [1950, 2215]},
        {type: SeminarIdentifier.Excel, ids: [1986, 2216]},
        {type: SeminarIdentifier.Finance, ids: [2237]},
        {type: SeminarIdentifier.Fin4Women, ids: [2338]}
    ]


    seminarFeedbackData: FormatToFeedbackOverviewData[] = [
        {type: SeminarIdentifier.Steuer , feedbackData: <FeedBackOverviewData>{}},
        {type: SeminarIdentifier.Thesis , feedbackData: <FeedBackOverviewData>{}},
        {type: SeminarIdentifier.Excel , feedbackData: <FeedBackOverviewData>{}},
        {type: SeminarIdentifier.Finance , feedbackData: <FeedBackOverviewData>{}},
        {type: SeminarIdentifier.Fin4Women , feedbackData: <FeedBackOverviewData>{}},
        {type: SeminarIdentifier.All , feedbackData: <FeedBackOverviewData>{}},
    ]

    constructor(public promotionApiResourceV2: PromotionApiResourceV2) {
        this.prepareAllFormatIds()
    }

    prepareAllFormatIds() {
        let allFormatIds: number[] = []

        this.formatToIds.forEach(it => {
                it.ids.flat().forEach(id => {
                    allFormatIds.push(id)
                })
            }
        )
        this.formatToIds.push({type: SeminarIdentifier.All, ids: allFormatIds})
    }

}
