import {FeedBackOverviewData} from "../../generated/cronos/data";
import {SeminarIdentifier} from "./feedback-selector.service";

export class FormatFeedbackGroup{

  data: FeedBackOverviewData;

  constructor(
    public identifier:SeminarIdentifier,
    public formatIds: number[]
  ) {}

  getTotalCount():number {
    if (!this.data) return
    return this.data.count1Stars + this.data.count2Stars + this.data.count3Stars + this.data.count4Stars + this.data.count5Stars
  }
  getCompactCount():string{
    let sum = this.getTotalCount()
    if(sum < 1e3) return sum.toString()
    return (sum / 1e3).toFixed(0)+"K+"
  }
  getAverageRating(){
    if(!this.data) return
    return ((this.data.count1Stars + this.data.count2Stars * 2 + this.data.count3Stars *3 + this.data.count4Stars *4  + this.data.count5Stars * 5 )/ this.getTotalCount()).toFixed(1)
  }
}
