import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../../api-resource";
import {JsonWrappedValue, ExpertProgressData, DisplayQuestionData, QuestionCategoryData, DisplayCommentData, CreateAnswerData, FilterQuestionData, CreateQuestionData, PagedData} from "../data"

/**
 * This resource class was generated from Controller de.syntacton.cronos.controller.externalv2.QuestionController
 */

@Injectable()
@ResourceParams({})
export class QuestionResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/question/answer/{answerId}/comment/create',
        endpoint: 'cronos'
    })
    private _addCommentToAnswer: IResourceMethodObservableStrict<JsonWrappedValue<string>, {}, {answerId: number}, void>;
    addCommentToAnswer(requestBody: JsonWrappedValue<string>, answerId: number): Promise<void> {
        return this._addCommentToAnswer(requestBody, null, {answerId: answerId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/question/{questionId}/comment/create',
        endpoint: 'cronos'
    })
    private _addCommentToQuestion: IResourceMethodObservableStrict<JsonWrappedValue<string>, {}, {questionId: number}, void>;
    addCommentToQuestion(requestBody: JsonWrappedValue<string>, questionId: number): Promise<void> {
        return this._addCommentToQuestion(requestBody, null, {questionId: questionId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/question/answer',
        endpoint: 'cronos'
    })
    private _answerQuestion: IResourceMethodObservableStrict<CreateAnswerData, {}, {}, void>;
    answerQuestion(requestBody: CreateAnswerData): Promise<void> {
        return this._answerQuestion(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/question/ask',
        endpoint: 'cronos'
    })
    private _askQuestion: IResourceMethodObservableStrict<CreateQuestionData, {}, {}, void>;
    askQuestion(requestBody: CreateQuestionData): Promise<void> {
        return this._askQuestion(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Delete,
        path: '/question/answer/{answerId}/delete',
        endpoint: 'cronos'
    })
    private _deleteAnswer: IResourceMethodObservableStrict<void, {}, {answerId: number}, void>;
    deleteAnswer(answerId: number): Promise<void> {
        return this._deleteAnswer(null, null, {answerId: answerId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Delete,
        path: '/question/comment/{commentId}/delete',
        endpoint: 'cronos'
    })
    private _deleteComment: IResourceMethodObservableStrict<void, {}, {commentId: number}, void>;
    deleteComment(commentId: number): Promise<void> {
        return this._deleteComment(null, null, {commentId: commentId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Delete,
        path: '/question/{questionId}/delete',
        endpoint: 'cronos'
    })
    private _deleteQuestion: IResourceMethodObservableStrict<void, {}, {questionId: number}, void>;
    deleteQuestion(questionId: number): Promise<void> {
        return this._deleteQuestion(null, null, {questionId: questionId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/question/answer/{answerId}/edit',
        endpoint: 'cronos'
    })
    private _editAnswer: IResourceMethodObservableStrict<CreateAnswerData, {}, {answerId: number}, void>;
    editAnswer(requestBody: CreateAnswerData, answerId: number): Promise<void> {
        return this._editAnswer(requestBody, null, {answerId: answerId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/question/comment/{commentId}/edit',
        endpoint: 'cronos'
    })
    private _editComment: IResourceMethodObservableStrict<JsonWrappedValue<string>, {}, {commentId: number}, void>;
    editComment(requestBody: JsonWrappedValue<string>, commentId: number): Promise<void> {
        return this._editComment(requestBody, null, {commentId: commentId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/question/{questionId}/edit',
        endpoint: 'cronos'
    })
    private _editQuestion: IResourceMethodObservableStrict<CreateQuestionData, {}, {questionId: number}, void>;
    editQuestion(requestBody: CreateQuestionData, questionId: number): Promise<void> {
        return this._editQuestion(requestBody, null, {questionId: questionId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/question/answer/{answerId}/comments',
        endpoint: 'cronos'
    })
    private _getAnswerComments: IResourceMethodObservableStrict<void, {}, {answerId: number}, DisplayCommentData[]>;
    getAnswerComments(answerId: number): Promise<DisplayCommentData[]> {
        return this._getAnswerComments(null, null, {answerId: answerId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/question/expertProgress',
        endpoint: 'cronos'
    })
    private _getExpertProgress: IResourceMethodObservableStrict<void, {}, {}, ExpertProgressData[]>;
    getExpertProgress(): Promise<ExpertProgressData[]> {
        return this._getExpertProgress(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/question/own',
        endpoint: 'cronos'
    })
    private _getOwnQuestions: IResourceMethodObservableStrict<void, {page: number, pageSize: number}, {}, PagedData<DisplayQuestionData>>;
    getOwnQuestions(requestParams: {page: number, pageSize: number}): Promise<PagedData<DisplayQuestionData>> {
        return this._getOwnQuestions(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/question/own/unanswered',
        endpoint: 'cronos'
    })
    private _getOwnUnansweredQuestions: IResourceMethodObservableStrict<void, {page: number, pageSize: number}, {}, PagedData<DisplayQuestionData>>;
    getOwnUnansweredQuestions(requestParams: {page: number, pageSize: number}): Promise<PagedData<DisplayQuestionData>> {
        return this._getOwnUnansweredQuestions(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/question/popular',
        endpoint: 'cronos'
    })
    private _getPopularQuestions: IResourceMethodObservableStrict<void, {page: number, pageSize: number}, {}, PagedData<DisplayQuestionData>>;
    getPopularQuestions(requestParams: {page: number, pageSize: number}): Promise<PagedData<DisplayQuestionData>> {
        return this._getPopularQuestions(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/question/popular/unanswered',
        endpoint: 'cronos'
    })
    private _getPopularUnansweredQuestions: IResourceMethodObservableStrict<void, {page: number, pageSize: number}, {}, PagedData<DisplayQuestionData>>;
    getPopularUnansweredQuestions(requestParams: {page: number, pageSize: number}): Promise<PagedData<DisplayQuestionData>> {
        return this._getPopularUnansweredQuestions(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/question/category',
        endpoint: 'cronos'
    })
    private _getQuestionCategories: IResourceMethodObservableStrict<void, {}, {}, QuestionCategoryData[]>;
    getQuestionCategories(): Promise<QuestionCategoryData[]> {
        return this._getQuestionCategories(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/question/category/{questionId}',
        endpoint: 'cronos'
    })
    private _getQuestionCategory: IResourceMethodObservableStrict<void, {}, {questionId: number}, QuestionCategoryData>;
    getQuestionCategory(questionId: number): Promise<QuestionCategoryData> {
        return this._getQuestionCategory(null, null, {questionId: questionId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/question/{questionId}/comments',
        endpoint: 'cronos'
    })
    private _getQuestionComments: IResourceMethodObservableStrict<void, {}, {questionId: number}, DisplayCommentData[]>;
    getQuestionComments(questionId: number): Promise<DisplayCommentData[]> {
        return this._getQuestionComments(null, null, {questionId: questionId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/questions',
        endpoint: 'cronos'
    })
    private _getQuestionsByFilter: IResourceMethodObservableStrict<FilterQuestionData, {page: number, pageSize: number}, {}, PagedData<DisplayQuestionData>>;
    getQuestionsByFilter(requestBody: FilterQuestionData, requestParams: {page: number, pageSize: number}): Promise<PagedData<DisplayQuestionData>> {
        return this._getQuestionsByFilter(requestBody, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/question/{questionId}',
        endpoint: 'cronos'
    })
    private _getSingleQuestion: IResourceMethodObservableStrict<void, {}, {questionId: number}, DisplayQuestionData>;
    getSingleQuestion(questionId: number): Promise<DisplayQuestionData> {
        return this._getSingleQuestion(null, null, {questionId: questionId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/question/answer/{answerId}/accept',
        endpoint: 'cronos'
    })
    private _markAnswerAsAccepted: IResourceMethodObservableStrict<void, {}, {answerId: number}, void>;
    markAnswerAsAccepted(answerId: number): Promise<void> {
        return this._markAnswerAsAccepted(null, null, {answerId: answerId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/question/answer/{answerId}/vote/reset',
        endpoint: 'cronos'
    })
    private _resetAnswerVote: IResourceMethodObservableStrict<void, {}, {answerId: number}, void>;
    resetAnswerVote(answerId: number): Promise<void> {
        return this._resetAnswerVote(null, null, {answerId: answerId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/question/{questionId}/vote/reset',
        endpoint: 'cronos'
    })
    private _resetQuestionVote: IResourceMethodObservableStrict<void, {}, {questionId: number}, void>;
    resetQuestionVote(questionId: number): Promise<void> {
        return this._resetQuestionVote(null, null, {questionId: questionId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/question/answer/{answerId}/unaccept',
        endpoint: 'cronos'
    })
    private _unmarkAnswerAsAccepted: IResourceMethodObservableStrict<void, {}, {answerId: number}, void>;
    unmarkAnswerAsAccepted(answerId: number): Promise<void> {
        return this._unmarkAnswerAsAccepted(null, null, {answerId: answerId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/question/answer/{answerId}/vote/down',
        endpoint: 'cronos'
    })
    private _voteAnswerDown: IResourceMethodObservableStrict<void, {}, {answerId: number}, void>;
    voteAnswerDown(answerId: number): Promise<void> {
        return this._voteAnswerDown(null, null, {answerId: answerId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/question/answer/{answerId}/vote/up',
        endpoint: 'cronos'
    })
    private _voteAnswerUp: IResourceMethodObservableStrict<void, {}, {answerId: number}, void>;
    voteAnswerUp(answerId: number): Promise<void> {
        return this._voteAnswerUp(null, null, {answerId: answerId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/question/{questionId}/vote/down',
        endpoint: 'cronos'
    })
    private _voteQuestionDown: IResourceMethodObservableStrict<void, {}, {questionId: number}, void>;
    voteQuestionDown(questionId: number): Promise<void> {
        return this._voteQuestionDown(null, null, {questionId: questionId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/question/{questionId}/vote/up',
        endpoint: 'cronos'
    })
    private _voteQuestionUp: IResourceMethodObservableStrict<void, {}, {questionId: number}, void>;
    voteQuestionUp(questionId: number): Promise<void> {
        return this._voteQuestionUp(null, null, {questionId: questionId}).toPromise()
    }

}